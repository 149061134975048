*{
  margin: 0;
  padding: 0;    
  text-decoration: none;
  box-sizing: border-box;
}

@font-face {
  font-family:'Briskolage-SemiBold';
  src: url(./Fonts/Satoshi_Complete/Fonts/OTF/BricolageGrotesque-SemiBold-BF648bd578894ae.otf);
}

@font-face {
  font-family:'Briskolage-Medium';
  src: url(./Fonts/Satoshi_Complete/Fonts/OTF/BricolageGrotesque-Medium-BF648bd578394c9.otf)
}

@font-face {
  font-family:'Briskolage-Regular';
  src: url(./Fonts/Satoshi_Complete/Fonts/OTF/BricolageGrotesque-Regular-BF648bd5781d794.otf);
}

@font-face {
  font-family:'Briskolage-Light';
  src: url(./Fonts/Satoshi_Complete/Fonts/OTF/BricolageGrotesque-Light-BF648bd57867903.otf);
}


