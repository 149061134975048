.portfolio{
    padding: 0 8%;
    padding-top: 150px;
    padding-bottom: 100px;
    background: #f9f9f9;
}
h2{
    font-family: 'Briskolage-SemiBold';
}

.talent-profile{
    display: flex;
    gap: 30px;
    font-size: 84px;
     font-family: 'Briskolage-SemiBold';
     padding-bottom: 30px;
     border-bottom: 2px solid #dfdfdf;
}

.talent-name p{
    font-size: 14px; 
    font-family: 'Briskolage-Regular';
}

.work{
    width: 90%;
    margin: 0 auto;
    margin-top: 100px;
}


.work-con{
    display: flex;
    flex-direction: column;
    gap: 80px;
    margin-top: 70px;
}

.work-box{
    display: flex;
    justify-content: space-between;
}

.work-text{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-basis: 40%;
}

.work-text span{
    font-size: 42px;
    font-family: 'Briskolage-SemiBold';
}

.work-text p{
    font-size: 16px;
}

.class-info{
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  margin-top: 30px;
}

.info-box span{
    color: #8b8b8b;
   font-size: 14px; 
   font-family: 'Briskolage-Regular';
}

.info-box p{
    margin-top: 5px;
   font-size: 16px ;
   font-family: 'Briskolage-SemiBold';
}

.work-img{
    display: flex;
    flex-basis: 55%;
    gap: 20px;
}

.work-img img{
    width: 100%;
    object-fit: cover;
    cursor: pointer;
}

.secondary-img {
    display: flex;
    gap: 20px;
    flex-direction: column;
}


.secondary-img img{
    padding: 5px;
    border: 2px solid #8b8b8b;
    border-radius: 10px;
    width: 100px;
    height: 60px;
    object-fit: cover;
    cursor: pointer;
}


.more-dir{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 100px;
}

.more-dir button{
    background: transparent;
    font-family:'Satoshi-Medium';
    padding: 10px 20px;
    border: 2px solid #E8E8E8;
    border-radius: 30px;
    cursor: pointer;
}

.bio-detail{
    font-size: 16px;
    line-height: 30px;
    font-family: 'Briskolage-Regular';
}

@media screen and (max-width:900px){
    .talent-profile{
        display: flex;
        flex-direction: column;
        gap: 10px;
        font-size: 48px;
         font-family: 'Briskolage-SemiBold';
         border-bottom: none;
    }
    
    .work{
        margin-top: 50px;
    }

    .work-con{
        margin-top: 30px;     
     }

    .work-box{
        flex-direction: column;
    }

    .work h2{
        font-size: 28px;
    }


    .work h4{
        margin-top: 5px;
        font-size: 24px;
        font-family:'Satoshi-Bold';
    }
    
    .work-text{
        flex-basis: 100%;
    }

    .work-text h2{
        font-size: 20px;
        font-family:'Satoshi-Bold';
    }

    .work-text p{
        font-size: 14px;
        margin-top: 5px;
    }
    
    
    .work-img{
        margin-top: 30px;
        flex-direction: column;
        flex-basis: 100%;
        gap: 20px;
    }

    .work-img img{
        width: 100%;
        height: auto;
        object-fit: cover;
        cursor: pointer;
    }

    .secondary-img {
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: center;
        gap: 20px;
    }

    .secondary-img img{
        padding: 5px;
        border-radius: 10px;
        width: 100px;
        height: 60px;
        object-fit: cover;
        cursor: pointer;
    }

    
    .bio-detail{
        margin-top: 30px;
        font-size: 18px;
        line-height: 30px;
        font-family: 'Briskolage-Regular';
    }
    
    
}