.hero {
    height: 100vh;
    background: #f2f2f2;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    position: relative; /* Important for positioning child elements */
    padding-top: 100px;
    overflow: hidden;
}

.hero h2 {
    width: 60%;
    margin: 0 auto;
    font-size: 4em;
    line-height: 78px;
    font-family: 'Briskolage-Light';
    margin-top: 15px;
    color: #141517;
}

.hero p {
    color: #141517;
    font-family: 'Briskolage-Medium';
}

.reel {
    position: absolute;
    top: 15%;
    left: 18%;
    width: 100px; /* Adjust the size as needed */
    height: 100px; /* Adjust the size as needed */
}

.record {
    position: absolute;
    top: 12%;
    right: 18%;
    width: 100px; /* Adjust the size as needed */
    height: 100px; /* Adjust the size as needed */
}

.mic {
    position: absolute;
    bottom: 7%;
    left: 10%;
    width: 100px; /* Adjust the size as needed */
    height: 100px; /* Adjust the size as needed */
}

.guitar{
    position: absolute;
    bottom: 10%;
    right: 20%;
    width: 100px; /* Adjust the size as needed */
    height: 100px; /* Adjust the size as needed */
}

@media screen and (max-width: 900px) {
    .hero {
        height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        text-align: center;
        position: relative; /* Important for positioning child elements */
        padding-top: 100px;
    }
    .hero h2 {
        width: 88%;
        margin: 0 auto;
        font-size: 2em;
        line-height: 48px;
        font-family: 'Briskolage-Light';
        margin-top: 15px;
    }

    .reel {
        position: absolute;
        top: 22%;
        left: 12%;
        width: 64px; /* Adjust the size as needed */
        height: 64px; /* Adjust the size as needed */
    }
    
    .record {
        position: absolute;
        top: 18%;
        right: 15%;
        width: 64px; /* Adjust the size as needed */
        height: 64px; /* Adjust the size as needed */
    }
    
    .mic {
        position: absolute;
        bottom: 4%;
        left: 10%;
        width: 100px; /* Adjust the size as needed */
        width: 64px; /* Adjust the size as needed */
        height: 64px; /* Adjust the size as needed */
    }
    
    .guitar{
        position: absolute;
        bottom: 5%;
        right: 15%;
        width: 64px; /* Adjust the size as needed */
        height: 64px; /* Adjust the size as needed */
    }
    
}
