.brief {
  padding: 0 8%;
  display: flex;
  justify-content: space-between;
}

.brief-left {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-basis: 45%;
  padding: 60px 0;
}

.brief-left h2 {
  font-size: 48px;
  margin-bottom: 20px;
  color: #141517;
  font-family: 'Briskolage-Semibold';
}

.brief-left p {
  color: #3f3f3f;
  font-family: 'Briskolage-Regular';
  font-size: 18px;
  line-height: 28px;
}

.brief-right {
  flex-basis: 45%;
  overflow: hidden;
}

.img-container {
  position: relative;
  overflow: hidden;
}

.img-container:after {
  content: "";
  position: absolute;
  width: 0%;
  height: 100%;
  top: 0;
  left: 0;
  background: #000000; /* Overlay color */
  transition: width 0.8s ease; /* Smooth transition for overlay width */
}

.img-container img {
  width: 100%;
  height: 100%;
  opacity: 0; /* Start with image hidden */
  transform: scale(1.3); /* Initial scale for a smooth zoom-in effect */
  transition: transform 1s ease, opacity 0.8s ease; /* Smooth transition for opacity and scaling */
}

@media screen and (max-width: 900px) {
  .brief {
    padding: 0 6%;
    flex-direction: column;
    gap: 0;
  }

  .brief-left {
    flex-basis: 100%;
    padding-top: 60px;
  }

  .brief-right {
    flex-basis: 100%;
  }

  .brief-left h2 {
    font-size: 32px;
    margin-bottom: 20px;
    font-family: 'Briskolage-Semibold';
  }
}
