/* General styles for the navbar */
.navbar {
  width: 100%;
  position: fixed;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2% 8%;
  height: 100px;
  background: #f8f8f8;
  z-index: 10;
}

/* Logo styling */
.logo {
  flex-basis: 40%;
}

.logo a img {
  margin-left: -30px;
  width: 120px;
  height: 120px;
  object-fit: cover;
}

/* Navigation items (menu) */
.nav-items {
  display: flex;
  align-items: center;
  gap: 30px;
  flex-shrink: 60%;
}

nav {
  display: flex;
  align-items: center;
  gap: 40px;
}

nav a li {
  color: #141517;
  list-style: none;
  font-family: 'Briskolage-Light';
  transition: all ease-in 0.5s;
}

nav a li:hover {
  color: #c21420;
}

nav a li.active {
  color: #c21420;
  font-family: 'Briskolage-Medium';
}

/* Button styling */
.nav-btn {
  display: inline-table;
  position: relative;
  z-index: 2;
  transition: all ease 1s;
  font-size: 14px;
  color: #c21420;
  background: transparent;
  padding: 18px 50px;
  border-radius: 60px;
  font-family: 'Briskolage-Regular';
  cursor: pointer;
  border: 1px solid #c21420;
}

.nav-btn::before {
  content: '';
  position: absolute;
  width: 0%;
  height: 100%;
  top: 0;
  left: 0;
  background: #c21420;
  z-index: -1;
  transition: all ease 1s;
}

.nav-btn:hover::before {
  width: 100%;
  border-radius: 60px;
}

.nav-btn:hover {
  color: #f8f8f8;
}

/* Menu toggle (hamburger) - Hidden on larger devices by default */
.menu-toggle {
  display: none;
  background: transparent;
}

/* Hamburger menu box and lines */
.hamBox {
  position: relative;
  border-radius: 10px;
  cursor: pointer;
  transition: 0.3s ease;
  padding: 1.4em;
  z-index: 3;
}

.hamBoxOpen {
  background: transparent;
}

.lineTop, .lineBottom {
  margin: 0 auto;
  position: absolute;
  display: block;
  width: 24px;
  height: 2px;
  border-radius: 10px;
  background: #8b8b8b;
  left: 0;
  right: 0;
  transform: rotate(0deg);
  transition: all 0.4s;
}

.lineTop {
  top: 1em;
}

.lineBottom {
  bottom: 1em;
}

.lineTop.spin {
  top: 1.4em;
  transform: rotate(135deg);
}

.lineBottom.spin {
  top: 1.4em;
  transform: rotate(225deg);
}


/* Fixed component menu */
.fixed-component {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  width: 95%;
  margin: 0 auto;
  margin-top: 110px;
  height: auto;
  background: #fff;
  display: flex;
  font-size: 24px;
  border-radius: 20px;
  padding: 6px;
  z-index: 1000;
}

/* Media queries for smaller devices */
@media screen and (max-width: 900px) {
  /* Display the menu toggle on smaller screens */
  .menu-toggle {
    display: block;
  }

  /* Hide the main nav on smaller screens */
  nav {
    display: none;
  }

  .navbar {
    display: flex;
    justify-content: space-between;
    padding: 2% 5%;
  }

  .logo {
    flex-basis: 30%;
  }

  .nav-items {
    display: flex;
    align-items: center;
    gap: 10px;
    flex-shrink: 70%;
  }

  .nav-btn {
    display: inline-table;
    position: relative;
    z-index: 2;
    transition: all ease 1s;
    font-size: 14px;
    color: #c21420;
    background: transparent;
    padding: 12px 20px;
    border-radius: 60px;
    font-family: 'Briskolage-Regular';
    cursor: pointer;
    border: 1px solid #c21420;
  }

  /* Menu container inside the fixed component */
  .menu-con {
    width: 100%;
    background: #f2f2f2;
    display: flex;
    flex-direction: column;
    gap: 30px;
    padding: 20px;
    border-radius: 14px;
    z-index: 1000;
  }

  .menu-con p{
    font-size: 14px;
    font-family: 'Briskolage-Regular';

  }
  

  .menu-con a li {
    font-size: 24px;
    list-style: none;
    font-family: 'Briskolage-Regular';
    color: #8b8b8b;;
  }

  .menu-con a li.active {
    color: #c21420;
    font-family: 'Briskolage-SemiBold';
  }
}
