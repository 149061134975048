.booking{
    position: fixed;
    width: 100%;
    z-index: 3;
    height: 100vh;
    display: flex;
    background: #f2f2f2;
    justify-content: center;
    align-items: center;
    margin-top: 0;
    padding-top: 0;
}

.booking-con span{
    font-size: 48px;
    font-family: 'Briskolage-Light';
    margin-bottom: 20px;
}

.booking-con{
    width: 30%;
    margin: 0 auto;
    background: #fff;
    padding: 30px 40px;
    display: flex;
    flex-direction: column;
    border-radius: 20px;
    margin-top: -50px;
    font-family: 'Briskolage-Light';
}

.booking-con input{
    margin-top: 10px;
    margin-bottom: 20px;
    padding: .8rem 2rem ;
    border: .1rem solid #efefef;
    border-radius: .4rem;
    color: #3f3f3f;
}

.booking-con button{
    margin-top: 10px;
    border: none;
    padding: 10px;
    background: #c21420;
    border-radius: 40px;
    color: #fff;
    font-family: 'Briskolage-Regular';
    font-size: 18px;
}

.booking-con a{
    margin-top: 20px;
    text-align: center;
    color: #050505;
    font-family: 'Briskolage-Regular';
}
@media screen and (max-width: 900px) {
    .booking-con{
        width: 85%;
        margin: 0 auto;
        background: #fff;
        padding: 20px 15px;
        display: flex;
        flex-direction: column;
        border-radius: 20px;
        margin-top: -80px;
    }
    
    .booking-con span{
        font-size: 32px;
        font-family: 'Briskolage-Light';
    }

    .booking-con input{
        width: 100%;
        margin-top: 10px;
        margin-bottom: 20px;
        padding: .8rem 1rem ;
        border: .1rem solid #efefef;
        border-radius: .4rem;
        color: #3f3f3f;
    }
    
}