.ceo{
    margin-top: 60px;
    padding: 0 8%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 100px;
}

.ceo-left{
    flex-basis: 46%;
}

.ceo-left img{
    width: 100%;
}

.ceo-right{
    flex-basis: 46%;
}

.ceo-right h2{
    font-size: 48px;
    margin-bottom: 5px; 
    color: #141517;
    font-family:'Briskolage-Semibold';
      
}

.ceo-right h2 strong{
    color: #3f3f3f;
    font-size: 20px;
    font-family:'Briskolage-Regular';
}

.ceo-right p{
    color: #3f3f3f;
    font-family:'Briskolage-Regular';
    font-size: 18px;
    line-height: 30px;
}

.ceo-brief{
    color: #3f3f3f;
    font-family:'Briskolage-Regular';  
    line-height: 22px;
}

.quote{
    display: flex;
    gap: 10px;
    align-items: center;
}

.slide-con{
    margin-top: 20px;
    max-width: 280px;
    background: #f2f2f2;
    padding: 20px;
    border-radius: 20px;
}

.slider{
    background: #f2f2f2;
}

.slide-text{
    background: #f2f2f2;
}

.slide-con p{
    margin-bottom: 5px;
    list-style: 24px;
    font-weight: bold;
    background: #f2f2f2;
}

.slide-con span{
    background: #f2f2f2;
    font-family:'Briskolage-Regular';
    line-height: 24px;
}

.navigation{
    margin-top: 40px;
    display: flex;
    justify-content: space-between;
}

.pagination{
    display: flex;
    align-items: center;
    gap: 5px;
}

.slid{
    width: 10px;
    height: 10px;
    border-radius: 50px;
    background: #fff;
    cursor: pointer;
}

.eclipse{
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: #c21420;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all ease-in 0.3s;
    cursor: pointer;
}

.eclipse:hover{
    background: #d61522; 
}

.eclipse img{
    width: 20px;
    height: 20px;
    background: transparent;
    transform: rotate(-90deg);

}

/* Add this to your CSS file */
.slide.active {
    display: block;
  }
  
  .slide {
    display: none;
    transition: all ease-in 0.3s;
  }
  
  .slid.active {
    background-color: #c21420; /* Change to desired active color */
  }

  .media{
    width: 200px;
  }

  .media-con{
   display: flex;
   gap: 20px;
  }

  .media-box{
    width: 50px;
    height: 50px;
    margin-top: 20px;
    border-radius: 50%;
    background: #f2f2f2;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .media-box img{
    width: 30px;
    height: 30px;
  }

  .media p{
    font-size: 16px;
    line-height: 26px;
    color: #141517;
  }

  
@media screen and (max-width: 900px){
    .ceo{
        margin-top: 40px;
        padding: 0 8%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        gap: 40px;
    }
    
    .ceo-left{
        flex-basis: 100%;
    }
    
    .ceo-left img{
        width: 100%;
    }
    
    .ceo-right{
        flex-basis: 100%;
    } 

    .ceo-right h2{
        font-size: 32px;
        margin-bottom: 0; 
        font-family:'Briskolage-Semibold';       
    }

    .ceo-right p{
        font-size: 18px;
        line-height: 34px;
    }
    

    .quote{
        display: flex;
        gap: 20px;
        flex-wrap: wrap;
    }

    .media{
        width: 100%;
      }

      .media p{
        font-size: 16px;
        line-height: 26px;
        color: #141517;
      }
    
    
}