.footer{
    padding: 5% 8%;
    margin: 0 auto;
    justify-content: space-between;
    background: #141517;
    color: #fff;
}


.footer-header h2{
    color: #fff;
    font-size: 48px;
    font-family:'Briskolage-SemiBold'; 
}

.footer-header p{
    font-size: 16px;
    color: #999D9E;
    font-family:'Briskolage-Regular';
}

.footer-btn{
    margin-top: 40px;
    padding-bottom: 50px;
    border-bottom: 2px solid #292929;
}

.footer-btn button{
    display: inline-table;
    position: relative;
    z-index: 2;
    transition: all ease 1s;
    font-size: 14px;
    background: transparent;
    border: 2px solid #292929;
    padding: 18px 50px;
    border-radius: 60px;
    color: #f2f2f2;
    font-family:'Briskolage-Regular'; 
    cursor: pointer;
}

.footer-btn button::before{
    content: '';
    position: absolute;
    width: 0%;
    height: 100%;
    top: 0;
    left: 0;
    background: #c21420;
    z-index:-1 ;
    transition: all ease 1s;
    
}

.footer-btn button:hover::before{
    width: 100%;
    border: 2px solid transparent;
    border-radius: 60px;
}

.footer-btn button:hover{
    color: #f8f8f8;
}


.footer-con{
    margin-top: 60px;
    display: flex;
    justify-content: space-between;
}

.moment{
    display: flex;
    gap: 40px;
}

.moment div{
    display: flex;
    flex-direction: column;
    gap: 15px;
    font-family:'Briskolage-Regular';  
}


.moment div span{
    font-size: 14px;
    color: #999D9E;
}

.socials{
    display: flex;
    flex-direction: column;
    gap: 15px;
    font-family:'Briskolage-Regular';    
}

.socials span{
    font-size: 14px;
    color: #999D9E;
}

.socials div{
   display: flex;
   gap: 19px;
}

.socials div a{
    color:#f2f2f2;
}

@media screen and (max-width: 900px){
    .footer{
        padding: 15% 8%;
        margin: 0 auto;
        justify-content: space-between;
        background: #141517;
        color: #fff;
    }

    .footer-con{
        margin-top: 60px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 40px;
    }

    .footer-header h2{
        color: #fff;
        font-size: 32px;
        font-family:'Briskolage-SemiBold'; 
    }

    .socials div{
        display: flex;
        flex-wrap: wrap;
        gap: 25px;
     } 
    
}