.Music{
    padding: 5% 0;
    background: #141517;
    background: re;
}

.music{
    background: #141517;
    color: #fff;
}

.music-head{
    padding: 2% 8%;
    border-bottom: 1px solid #ccc;
}

.music-head h2{
    font-size: 48px;
    font-family:'Briskolage-Semibold';
}

.music-con{
   display: flex;
   justify-content: space-between;
   border-bottom: 1px solid #ccc;
}

.music-box{
    width: 100%;
    padding: 2% ;
}

.music-box p{
    font-family:'Briskolage-Semibold';
    margin-bottom: 20px;
}

.music-box span{
    color: #dbdbdb;
    font-size: 14px;
    font-family:'Briskolage-Regular';
    line-height: 24px;
}

#first-box{
    padding: 2%; 
    padding-left: 8%;
}

#mid-box{
    border-right: 1px solid #ccc;
    border-left: 1px solid #ccc;
}

#last-box{
    padding: 2%; 
    padding-right: 8%; 
}


@media screen and (max-width: 900px) {
    .Music{
        padding: 8% 0;
        background: #141517;
    }

    .music-head{
        padding: 6% 8%;
        padding-top: 8%;
        border-bottom: 1px solid #ccc;
    }

    .music-head h2{
        font-size: 32px;
        font-family:'Briskolage-Semibold';
    }

    .music-con{
        display: flex;
        justify-content: space-between;
        flex-direction: column;
     }
     
.music-box{
    padding: 2% 8%;
}

     #first-box{
        padding: 8%; 
        padding-left: 8%;
    }

    #mid-box{
        padding: 8%; 
        border-right: none;
        border-left: none;
        border-top: 1px solid #ccc;
        border-bottom: 1px solid #ccc;
    }

    
#last-box{
    padding: 8%; 
    padding-left: 8%; 
}
}