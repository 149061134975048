.port{
    background: #f2f2f2;
    padding: 5% 0;
    padding-bottom: 5%;
}

.port-con{
    padding: 2% 8%;
}

.port-con p{
   margin-top: 10px; 
}

.port-con h2{
    font-size: 48px;
    font-family:'Briskolage-Semibold';
    margin-top: 10px;
}

#no-manage{
    margin-top: 0;
}

.port-reel{
    display: flex;
    gap: 20px;
}

.port-box{
    max-width: 380px;
    background: #fff;
    padding: 10px;
}

.port-box img{
    width: 100%;
    height: 350px;
    object-fit: cover;
    margin-bottom: 20px;
}

.port-box span{
    font-size: 14px;
    color: #8b8b8b;
    font-family:'Briskolage-Regular'; 
}

.port-box p{
 font-size: 20px;
  margin-top: 10px; 
  font-family:'Briskolage-SemiBold';  
}

@media screen and (max-width: 900px){
    .port{
        background: #f2f2f2;
        padding: 10% 0;
        overflow: hidden;
    }

    .port-con{
        width: 85%;
        margin: 0 auto;
        padding: 2% 0;
    }
    .port-con h2{
        font-size: 32px;
        font-family:'Briskolage-Semibold';
    }

    .port-box{
        min-width: 280px;
        background: #fff;
        padding: 10px;
    }

    
    .port-reel{
        display: flex;
        gap: 20px;
        padding-left: 5%;
        overflow: scroll;
    }

        /* width */
    .port-reel::-webkit-scrollbar {
        width: 8px;
        scroll-behavior: smooth;
        display: none;
    }
  
       
}
