.about{
    padding: 0 8%;
    padding-top: 150px;
    background: #f9f9f9;
}

.insight {
    height: 100vh;
    margin-top: 60px;
    position: relative;
    overflow: hidden;
}

.insight video{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.insight-2022 {
    width: 100%;
}

.insight button {
    position: absolute;
    top: 40px;
    left: 40px;
    width: 100px;
    height: 100px;
    padding: 20px;
    border-radius: 50%;
    border: 2px solid #fff;
    background: rgba(0, 0, 0, 0.5);
    color: #fff;
    font-family: 'Briskolage-SemiBold';
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all ease-in 0.5s;
}

.insight button:hover{
    background: rgba(168, 8, 8, 0.5); 
}

h2{
    font-size: 48px;
    font-family:'Briskolage-SemiBold';    
}

.about-cta{
    display: flex;
    justify-content: space-between;
    align-items: last baseline;
}

.join-text{
    flex-basis: 65%;
}

.join-text p{
    font-size: 18px;
    line-height: 28px;
   margin-top: 20px;
  font-family:'Briskolage-Regular';
}

.join-cta{
    flex-basis: 20%;
}

.join-cta button{
    width: 100%;
    background: #c21420;
    border-radius: 30px;
    padding: 12px 16px;
    font-family:'Briskolage-Medium';
    border: none;
    color: #f9f9f9;
    outline: none;
    cursor: pointer;
}

.team-con{
    margin-top: 100px;  
    padding-bottom: 100px;
}

.team{
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
    gap: 20px;
}

.team-box{
    display: flex;
    flex-direction: column;
    width: 100%;
    background: #fff;
    padding: 15px;
}


.team-box p{
    font-family:'Briskolage-SemiBold'; 
    margin-bottom: 5px;  
}

.team-box span{
    color: #8b8b8b;
   font-family:'Briskolage-Regular';   
}

.team-box img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    margin-top: 20px;
}

.oiling{
    display: flex;
    justify-content: space-between;
    margin-top: 100px;
    gap: 50px;
}

.oiling h2{
    width: 100%;
}

.oiling p{
    font-size: 18px;
    line-height: 28px;
   font-family:'Briskolage-Regular';
}



@media screen and (max-width: 900px){
    .about-cta{
        display: flex;
        gap: 20px;
        flex-direction: column;
        justify-content: space-between;
        align-items: last baseline;
    }

    
    .join-text{
        flex-basis: 100%;
    }
    
    .join-text span{
        font-family:'Briskolage-SemiBold'; 
    }
    
    .join-text p{
        font-size: 18px;
        line-height: 28px;
       margin-top: 20px;
      font-family:'Briskolage-Regular';
    }
    
    .join-cta{
        flex-basis: 100%;
    } 

    
    .team-con{
        margin-top: 60px;  
        padding-bottom: 60px;
    }


    .team{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 40px;
    }

    .oiling{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-top: 60px;
        gap: 20px;
    }

    h2{
        font-size: 32px;
    }

    .insight{
        height: auto;
        max-height: none;
        margin-top: 40px;
        position: relative;
        overflow: hidden;
    }

    .insight button {
        position: absolute;
        top: 15px;
        left: 15px;
        width: 80px;
        height: 80px;
        padding: 20px;
        border-radius: 50%;
        border: 2px solid #fff;
        background: rgba(0, 0, 0, 0.5);
        color: #fff;
        font-family: 'Briskolage-SemiBold';
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all ease-in 0.5s;
    }
    

}
